import { theme } from 'styles';

export const transactionsContainer = {
  height: '100%',
  padding: '0 128px 0 100px',

  '.title': {
    margin: '32px 0',
  },

  '.table-head': {
    '@media (max-width: 992px)': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
  },

  '.container-body': {
    '.table-row': {
      '@media (max-width: 992px)': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    },
  },
};

export const columnContainer = {
  '.bold-text': {
    fontWeight: 600,

    '& > span': {
      fontWeight: 400,
    },
  },

  '.gray-text': {
    color: theme.gray300,

    '.bold': {
      color: theme.black,
    },
  },
};

export const coloredAmount = {
  fontWeight: 600,
  color: theme.black,
  fontSize: 14,
};

export const linkButton = {
  color: theme.blue,
  fontWeight: 500,
  padding: '0.3em 0',
};
