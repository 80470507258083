/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { isNil, isEmpty, camelCase, isArray } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import {
  Button,
  BackButton,
  Col,
  ColorBatch,
  Icon,
  Image,
  Row,
  showApiError,
  Skeleton,
  BookingRoomCart,
  CancelBookingModal,
  CheckInOutPetsCart,
  PaymentCart,
  PolicyCart,
  TransactionPaymentDetails,
  useUrlParams,
} from 'components';
import { BOOKING_STATUSES, BOOKING_STATUS_COLOR } from 'enums';
import { validDate } from 'utils';
import { getBookingById } from 'services';
import { bookingDetailsContainer, maps } from './styles';

const BookingDetails = ({ history }) => {
  const { getText } = useTranslations();
  const { setQueryParams } = useUrlParams();
  const params = useParams();
  const modalRef = useRef();
  const [booking, setBooking] = useState({});
  const bookingStatus = history?.location?.state?.booking?.bookingStatus ?? booking?.status;
  const bookingId = params?.bookingId ?? history.location?.state?.booking?.id;
  const confirmationNumber =
    history.location?.state?.booking?.supplierConfirmationNum ?? booking?.supplierConfirmationNum;
  const urlParams = history.location?.state?.queryParams;

  useEffect(() => {
    setQueryParams(urlParams);
    fetchHotelDetails();
    handleScrollToTop();
  }, []);

  const fetchHotelDetails = async () => {
    const [res, err] = await getBookingById(bookingId);
    err ? showApiError(err) : setBooking(res);
  };

  const handleChangePage = () => {
    window.open(
      `${applicationUrls.voyagePortalUrl}hotels/${booking?.hotelId}/overview?provider=${
        booking?.providerName
      }&hide=${true}`,
      '_blank',
    );
  };

  const handleScrollToTop = () =>
    document.getElementById('#header').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

  return (
    <div css={bookingDetailsContainer} id="#header">
      <div className="container">
        <BackButton />
        <div className="name-location-action-image-container">
          <div className="name-location-image-container">
            {!isNil(booking?.hotelImages) ? (
              <Image
                src={isArray(booking?.hotelImages) ? booking?.hotelImages[0] : booking?.hotelImages}
                className="hotel-image"
              />
            ) : (
              <Skeleton className="hotel-image" />
            )}

            <div>
              <div className="booking-badge-id-container">
                {isNil(booking?.bookingId) ? (
                  <Skeleton width={100} height={23} className="booking-id" />
                ) : (
                  <p className="booking-id">{`${getText('booking')} ${booking?.bookingId}`}</p>
                )}
                <ColorBatch type={BOOKING_STATUS_COLOR[bookingStatus]?.color} className="booking-badge">
                  {getText(`${camelCase(bookingStatus)}`)}
                </ColorBatch>
              </div>

              {isNil(booking?.hotelName) ? (
                <Skeleton width={600} height={24} className="title" />
              ) : (
                <h4 className="title">{booking?.hotelName}</h4>
              )}

              {isNil(booking?.hotelId) ? (
                <Skeleton width={200} height={17} className="location" />
              ) : (
                <h4 className="location">{`# ${booking?.hotelId}`}</h4>
              )}

              {isNil(booking?.address) ? (
                <Skeleton width={600} height={17} className="location" />
              ) : (
                <div className="location">
                  {booking?.address?.address && <span>{`${booking?.address?.address}`}</span>}
                  {booking?.address?.cityName && <span>{`, ${booking?.address?.cityName}`}</span>}
                  {booking?.address?.stateName && <span>{`, ${booking?.address?.stateName}`}</span>}
                  {booking?.address?.countryName && <span>{`, ${booking?.address?.countryName}`}</span>}
                </div>
              )}
            </div>
          </div>

          <div className="action-container">
            <Button type="link" className="booking-details-button" onClick={handleChangePage}>
              {getText('propertyDetails')}
              <Icon material iconName="chevron_right" className="icon-expand" />
            </Button>

            {bookingStatus === BOOKING_STATUSES.PAYMENT_COMPLETED &&
              (isNil(booking?.status) ? (
                <Skeleton width={152} height={48} />
              ) : (
                <Button type="danger" className="danger-button" onClick={() => modalRef.current.open()}>
                  {getText('cancelBooking')}
                </Button>
              ))}
          </div>
        </div>

        <hr className="horizontal-line" />

        <Row className="booking-details" horizontalGap={32}>
          <Col xs={12} sm={12} md={7} lg={7}>
            <h4 className="section-title">{getText('paymentDetails')}</h4>
            {isNil(booking?.priceDetails) ? (
              <Skeleton width="100%" height={170} />
            ) : (
              <PaymentCart paymentInfo={booking?.priceDetails} invoiceInfo={booking} bookingStatus={booking.status} />
            )}

            <h4 className="section-title">{getText('bookingDetails')}</h4>

            {(validDate(booking?.checkInTime ?? booking.checkIn) &&
              validDate(booking?.checkOutTime ?? booking.checkOut)) ||
            !isNil(booking?.petFriendlyData) ? (
              <CheckInOutPetsCart hotelDetails={booking} />
            ) : (
              <Skeleton width="100%" height={136} />
            )}

            {!isNil(booking?.roomDetails) && <h4 className="section-title">{getText('roomDetails')}</h4>}
            {isEmpty(booking) ? (
              <Skeleton width="100%" height={507} />
            ) : (
              !isNil(booking?.roomDetails) && <BookingRoomCart bookingInfo={booking?.roomDetails} booking={booking} />
            )}

            {!isNil(booking?.hotelPolices) && (
              <>
                <h4 className="section-title">{getText('hotelPolicies')}</h4>
                <PolicyCart policies={booking?.hotelPolices} />
              </>
            )}

            {!isNil(booking?.location) && <h4 className="section-title">{getText('whereYouWillBe')}</h4>}
            {isEmpty(booking) ? (
              <Skeleton width="100%" height={350} />
            ) : (
              !isNil(booking?.location) && (
                <iframe
                  src={`https://maps.google.com/maps?q=${booking?.location?.latitude},${booking?.location?.longitude}&hl=es;&output=embed`}
                  width="100%"
                  height="380"
                  style={maps}
                  aria-hidden="false"
                />
              )
            )}
          </Col>

          <Col xs={12} sm={12} md={5} lg={5}>
            <h4 className="user-styles">{getText('userDetails')}</h4>

            <div className="user-container">
              <div className="user-info-container">
                {(booking?.bookingUser?.firstName !== '' && !isNil(booking?.bookingUser?.firstName)) ||
                  (booking?.bookingUser?.lastName !== '' && !isNil(booking?.bookingUser?.lastName) && (
                    <p className="blue-text">{`${booking?.bookingUser?.firstName} ${booking?.bookingUser?.lastName}`}</p>
                  ))}
                {booking?.bookingUser?.email && <p className="gray-text">{booking?.bookingUser?.email}</p>}
                {booking?.bookingUser?.phone && <p className="gray-text">{booking?.bookingUser?.phone}</p>}
                {booking?.bookingUser?.userId && <p className="gray-text">{booking?.bookingUser?.userId}</p>}
              </div>

              {(booking?.bookingUser?.firstName !== '' && !isNil(booking?.bookingUser?.firstName)) ||
                (booking?.bookingUser?.lastName !== '' && !isNil(booking?.bookingUser?.lastName) && (
                  <div className="user-names">
                    {`${booking?.bookingUser?.firstName?.substring(0, 1)}${booking?.bookingUser?.lastName?.substring(
                      0,
                      1,
                    )}`}
                  </div>
                ))}
            </div>

            <h4 className="section-title">{getText('transactionDetails')}</h4>
            {!isEmpty(booking?.transactions) ? (
              booking?.transactions?.map((el, index) => (
                <TransactionPaymentDetails key={index} transaction={el} fromBooking className="transaction-details" />
              ))
            ) : (
              <Skeleton width={500} height={507} className="transaction-details" />
            )}
          </Col>
        </Row>
      </div>

      <CancelBookingModal
        ref={modalRef}
        bookingId={booking?.bookingId}
        confirmationNumber={confirmationNumber}
        urlParams={urlParams}
      />
    </div>
  );
};

export default BookingDetails;
