import { getReq, postReq, patchReq, getAllSubscriptionsParams, getAllSubscriptionModel } from 'services';

const baseUrl = `${apiUrls.voyageApiUrl}/api/Subscriptions`;

export const getAllSubscriptions = async (options) => {
  const [res, err] = await getReq(`${baseUrl}/all?${getAllSubscriptionsParams(options)}`);
  return [getAllSubscriptionModel(res), err];
};

export const createSubscription = (data) => postReq(`${baseUrl}`, data);

export const updateSubscription = (subscriptionCatalogueId, data) =>
  patchReq(`${baseUrl}/${subscriptionCatalogueId}`, data);
