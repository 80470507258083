import { getReq, patchReq, postReq, putReq, getPaymentMethodsParams } from 'services';

const baseUrl = `${apiUrls.voyageApiUrl}/api/PaymentMethods`;

export const getAllPaymentMethods = (options) => getReq(`${baseUrl}/all?${getPaymentMethodsParams(options)}`);

export const createPaymentMethod = (data) => postReq(`${baseUrl}`, data);

export const updatePaymentMethod = (paymentMethodName, data) =>
  patchReq(`${baseUrl}/${encodeURIComponent(paymentMethodName)}`, data);

export const getAllPaymentMethodsByFeature = (featureName) => getReq(`${baseUrl}/${featureName}`);

export const updateFeaturesPaymentMethods = (data) => putReq(`${baseUrl}/Features`, data);
