import { isArray, differenceBy, isEmpty, isNumber, pick, isNil } from 'lodash-es';
import queryString from 'query-string';
import moment from 'moment';
import { BOOKINGS_SORTING_OPTIONS, HIGHLIGHTED_HOTEL_AMENITIES } from 'enums';

export const getAllBookingsParams = (options = {}) => {
  const {
    bookingId,
    creationDate,
    checkIn,
    checkOut,
    fiatPriceFrom,
    fiatPriceTo,
    cryptoPriceFrom,
    cryptoPriceTo,
    status,
    userId,
    email,
  } = options;

  const dateRange = {};
  const amountPriceRange = {};
  const cryptoPriceAmountRange = {};

  if (checkIn && checkOut) {
    dateRange.checkIn = moment(checkIn).format('YYYY-MM-DD');
    dateRange.checkOut = moment(checkOut).format('YYYY-MM-DD');
  }

  if (fiatPriceFrom && fiatPriceTo && fiatPriceFrom >= 0 && fiatPriceTo >= 0) {
    amountPriceRange.fiatPriceFrom = fiatPriceFrom;
    amountPriceRange.fiatPriceTo = fiatPriceTo;
  }

  if (cryptoPriceFrom && cryptoPriceTo && cryptoPriceFrom >= 0 && cryptoPriceTo >= 0) {
    cryptoPriceAmountRange.cryptoPriceFrom = cryptoPriceFrom;
    cryptoPriceAmountRange.cryptoPriceTo = cryptoPriceTo;
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(isNumber(bookingId) && { bookingId }),
      ...(!isEmpty(creationDate) && { creationDate: moment(creationDate).format('MM/DD/YYYY') }),
      ...(!isEmpty(dateRange) && dateRange),
      ...(!isEmpty(cryptoPriceAmountRange) && cryptoPriceAmountRange),
      ...(!isEmpty(amountPriceRange) && amountPriceRange),
      ...(isNumber(cryptoPriceFrom) && { cryptoPriceFrom }),
      ...(isNumber(cryptoPriceTo) && { cryptoPriceTo }),
      ...(isNumber(fiatPriceFrom) && { fiatPriceFrom }),
      ...(isNumber(fiatPriceTo) && { fiatPriceTo }),
      ...(isArray(status) && { status }),
      ...(!isNil(userId) && { userId }),
      ...(!isNil(email) && { email }),
      ...(options.sort ? { sortDirection: options.sort.sortOrder } : { sortDirection: BOOKINGS_SORTING_OPTIONS.DESC }),
    },
    { arrayFormat: 'index' },
  );
};

export const bookingDetailsByIdModel = (response) => {
  const hotelAmenitiesData = response?.amenities?.flatMap((el) =>
    el?.name?.toLowerCase()?.includes('<br />')
      ? el.name
          ?.toLowerCase()
          ?.split('<br />')
          ?.map((elem) => ({ ...el, name: elem.toLowerCase() }))
      : { ...el, name: el.name.toLowerCase() },
  );

  const hotelAmenities = differenceBy(hotelAmenitiesData, HIGHLIGHTED_HOTEL_AMENITIES, 'name');

  const address = {
    ...response?.address,
    address: response?.address?.address?.replaceAll('\t', ', '),
  };

  const bookingUserId = response?.transactions?.find((el) => el.senderUserId)?.senderUserId;

  return {
    ...response,
    hotelAmenities,
    address,
    bookingUser: {
      ...response?.bookingUser,
      userId: bookingUserId,
    },
    roomDetails: !isNil(response?.roomDetails)
      ? {
          ...response?.roomDetails,
          boardType: response?.roomDetails?.boardType?.replace('|t|', ', '),
          cancellationPolicy: response?.roomDetails?.cancellationPolicy?.replace('|t|', ' '),
          maxOccupancy: response?.roomDetails?.maxOccupancy?.replace('|t|', ' - '),
        }
      : null,
  };
};
